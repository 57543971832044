import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import filterUnapprovedEntries from '../filter-unapproved-entries';

export default ({ data, pageContext }) => {
  const { category, page, pages } = pageContext;

  let posts = filterUnapprovedEntries(
    data.allContentfulFaq.edges.map(({ node }) => node)
  );

  const categories = Array.from(
    new Set(posts.map(p => p.categories).flat())
  ).sort();

  if (category) {
    posts = posts.filter(p => p.categories.includes(category));
  }

  const pageLength = 3;
  const startIndex = page * pageLength;
  posts = posts.slice(startIndex, startIndex + pageLength);

  return (
    <Layout
      title="Transit FAQ"
      currentSection={category}
      mainClass="section-landing"
    >
      <div className="section-header">
        <h1 className="section-title">Transit FAQ</h1>
        {categories && (
          <ul className="section-subcategories">
            {categories.map(thisCategory => (
              <li key={thisCategory}>
                <Link
                  to={`/faq/category/${thisCategory.toLowerCase()}`}
                  className={`btn ${
                    category === thisCategory ? 'btn--active' : ''
                  }`}
                >
                  {thisCategory}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      {posts.length === 0 && (
        <p style={{ 'text-align': 'center' }}>No articles in this section</p>
      )}
      {posts.length > 0 && (
        <ul className="section-articles section-articles--faq">
          {posts.map(post => (
            <li key={`post-${post.slug}`}>
              <Link className="article-list__link" to={`/faq/${post.slug}`}>
                {post.heroImage && (
                  <img
                    className="article-list__image"
                    alt={post.heroImage?.title}
                    src={post.heroImage?.fluid?.src}
                  />
                )}
                {post.categories.map(thisCategory => (
                  <>
                    <div className="section-tag">{thisCategory}</div>{' '}
                  </>
                ))}
                <h3 className="article-list__title">{post.title}</h3>
              </Link>
            </li>
          ))}
        </ul>
      )}
      {page + 1 < pages && (
        <div style={{ textAlign: 'center', margin: '4rem auto' }}>
          <Link
            to={`/faq${
              category ? `/category/${category.toLowerCase()}` : ''
            }/page/${page + 2}`}
            className="btn"
            data-view-more
          >
            View More
          </Link>
        </div>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query FaqSection {
    allContentfulFaq(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM D, YYYY")
          categories
          heroImage {
            ... on ContentfulAsset {
              title
              fluid(maxWidth: 1180, background: "rgb:000000") {
                src
              }
            }
          }
          holdForApproval
          summary {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`;
